import React, { useRef, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import SEO from "../components/seo"
import BusinessLocation from "../components/business-location"
import Breadcrumbs from "../components/breadcrumbs"
import { ContactLink, HomeLink } from "../components/links"
import PhoneInput from "../components/phone-input"
import Alert from "../components/alert"

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)
  const handleChange = e => {
    setValue(e.target.value)
  }
  return {
    value,
    onChange: handleChange,
  }
}

const ContactPage = () => {
  const { tel, address } = useSiteMetadata()
  const firstName = useFormInput("")
  const lastName = useFormInput("")
  const email = useFormInput("")
  const message = useFormInput("")
  const firstNameVal = firstName.value
  const lastNameVal = lastName.value
  const emailVal = email.value
  const messageVal = message.value
  const phoneNumberRef = useRef()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [infoMessage, setInfoMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const clearMessages = () => {
    setErrorMessage("")
    setInfoMessage("")
    setSuccessMessage("")
  }

  const handleSubmit = async e => {
    clearMessages()
    e.preventDefault()

    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    if (!firstNameVal) {
      setInfoMessage(`Please don't leave the first name field empty.`)
      return
    } else if (!lastNameVal) {
      setInfoMessage(`Please don't leave the last name field empty.`)
      return
    } else if (!phoneNumberRef.current.getInputValue()) {
      setInfoMessage(`Please don't leave the phone number field empty.`)
      return
    } else if (!emailVal) {
      setInfoMessage(`Please don't leave email field empty.`)
      return
    } else if (!messageVal) {
      setInfoMessage(`Please don't leave the message field empty.`)
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const token = await executeRecaptcha("SendMessage")

    const data = JSON.stringify({
      firstName: firstNameVal,
      lastName: lastNameVal,
      phoneNumber: phoneNumberRef.current.getInputValue(),
      email: emailVal,
      message: messageVal,
      token,
    })

    setIsSubmitting(true)
    setInfoMessage("Sending message...")

    fetch("https://api.rbits.dev/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: data,
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP status ${res.status}`)
        }
        return res.json()
      })
      .then(data => {
        const message = (data && data.message) || "There was an unknown error"
        setIsSubmitting(false)
        clearMessages()
        setSuccessMessage(message)
      })
      .catch(error => {
        setIsSubmitting(false)
        clearMessages()
        setErrorMessage(
          "Oops, there was something wrong on our end. Please try again later."
        )
      })
  }

  return (
    <>
      <SEO title="Contact us" />

      <Breadcrumbs>
        <HomeLink />
        <ContactLink />
      </Breadcrumbs>

      <div className="prose dark:prose-invert max-w-none prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
        <h2>Contact us</h2>
        <div className="flex flex-col lg:flex-row lg:space-x-8">
          <div className="lg:basis-6/12">
            <h3 className="m-0 mt-2">Phone</h3>
            <a className="font-bold" href={"tel:" + tel}>
              {tel}
            </a>
            <h3 className="m-0 mt-4">Office Hours</h3>
            <table className="table-auto mb-0">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Hours</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tuesday, Wednesday, Friday</td>
                  <td>10:00 AM to 7:00 PM</td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td>9:30 AM to 5:30 PM</td>
                </tr>
                <tr>
                  <td>Monday, Thursday, Sunday</td>
                  <td>Closed</td>
                </tr>
              </tbody>
            </table>
            <h3 className="m-0 mt-4">Address</h3>
            <p className="m-0">{address}</p>
            <BusinessLocation placeId="ChIJk7QJ47fS1IkRecpmvZaSns0" />
          </div>
          <div className="lg:basis-6/12">
            <h3 className="m-0 mt-4 lg:mt-2">Send An Email</h3>
            <div className="m-0">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="form-control">
                  <label className="label" htmlFor="first-name">
                    <span className="label-text">First Name</span>
                  </label>
                  <input
                    id="first-name"
                    type="text"
                    placeholder="First Name"
                    className="input input-primary input-bordered"
                    required
                    disabled={isSubmitting}
                    {...firstName}
                  />
                </div>
                <div className="form-control">
                  <label className="label" htmlFor="last-name">
                    <span className="label-text">Last Name</span>
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    placeholder="Last Name"
                    className="input input-primary input-bordered"
                    required
                    disabled={isSubmitting}
                    {...lastName}
                  />
                </div>
                <div className="form-control">
                  <label className="label" htmlFor="phone-number">
                    <span className="label-text">Phone Number</span>
                  </label>
                  <PhoneInput
                    id="phone-number"
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    placeholder="123-123-1234"
                    className="input input-primary input-bordered"
                    required
                    disabled={isSubmitting}
                    ref={phoneNumberRef}
                  ></PhoneInput>
                </div>
                <div className="form-control">
                  <label className="label" htmlFor="email-address">
                    <span className="label-text">Email Address</span>
                  </label>
                  <input
                    id="email-address"
                    type="email"
                    placeholder="example@domain.com"
                    className="input input-primary input-bordered"
                    required
                    disabled={isSubmitting}
                    {...email}
                  />
                </div>
                <div className="form-control">
                  <label className="label" htmlFor="your-message">
                    <span className="label-text">Your Message</span>
                  </label>
                  <textarea
                    id="your-message"
                    className="textarea h-24 textarea-primary textarea-bordered"
                    placeholder="Your Message"
                    required
                    disabled={isSubmitting}
                    {...message}
                  ></textarea>
                </div>
                <div className="flex justify-center">
                  <button className="btn text-zinc-50" disabled={isSubmitting}>
                    Send
                  </button>
                </div>
                {infoMessage && (
                  <Alert>
                    <span className="text-base">{infoMessage}</span>
                  </Alert>
                )}
                {successMessage && (
                  <Alert type="success">
                    <span className="text-base">{successMessage}</span>
                  </Alert>
                )}
                {errorMessage && (
                  <Alert type="error">
                    <span className="text-base">{errorMessage}</span>
                  </Alert>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ContactPage
